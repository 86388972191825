<template>
  <header class="ag-inspires-header relative overflow-hidden px-8 md:px-10">
    <Container class="lg:hidden">
      <div class="pt-12 md:pt-16">
        <YourLogo v-if="logo === 'demo'" class="max-h-16 max-w-xxs" />
        <img
          v-else-if="logo"
          :src="logo?.full_url"
          :class="{
            'max-h-20 max-w-xxs': logo.full_url.includes('-ag-portrait'),
            'max-h-16 max-w-xs': logo.full_url.includes('-ag-landscape'),
            'max-h-16 max-w-xxs': !logo.full_url.includes('-ag-portrait') && !logo.full_url.includes('-ag-landscape'),
          }"
        />

        <div v-if="title || message" :class="{ 'mt-5': logo }">
          <h1 v-if="title" v-html="$markdown(title)" class="ag-inspires-header__title text-gray-900" />
          <p v-if="message" v-html="$markdown(message)" class="mt-3" />
        </div>
      </div>
    </Container>

    <div v-if="backgroundImage" class="relative -mr-8 mt-8 h-80 overflow-hidden rounded-l-lg md:-mr-10 lg:hidden">
      <Image :media="backgroundImage" responsive class="absolute inset-0 h-full w-full object-cover" />
    </div>

    <Container>
      <div class="lg:max-w-1/2 lg:pr-24">
        <div class="lg:flex lg:min-h-[16rem] lg:flex-col lg:justify-center lg:pr-20">
          <div class="lg:pt-20" :class="{ 'lg:pb-20': backgroundImage }">
            <div v-if="logo" class="hidden lg:block">
              <YourLogo v-if="logo === 'demo'" class="max-h-16 max-w-xxs" />
              <img
                v-else
                :src="logo.full_url"
                :class="{
                  'max-h-20 max-w-xxs': logo.full_url.includes('-ag-portrait'),
                  'max-h-16 max-w-xs': logo.full_url.includes('-ag-landscape'),
                  'max-h-16 max-w-xxs': !logo.full_url.includes('-ag-portrait') && !logo.full_url.includes('-ag-landscape'),
                }"
              />
            </div>

            <div v-if="title || message" class="hidden lg:block" :class="{ 'mt-5': logo }">
              <h1 v-if="title" v-html="$markdown(title)" class="ag-inspires-header__title text-gray-900" />
              <p v-if="message" v-html="$markdown(message)" class="mt-3" />
            </div>

            <div class="mt-8 md:mt-10">
              <p v-if="personalMessage" class="ag-inspires-header__quote rounded-lg border p-6 italic md:px-7">
                "<span v-html="$markdown(personalMessage)" />"
              </p>

              <div class="flex space-x-5" :class="{ 'mt-6 px-3': personalMessage }">
                <img
                  v-if="personalPhoto"
                  loading="lazy"
                  :src="personalPhoto.thumb_url"
                  class="h-[60px] w-[60px] rounded-full object-cover"
                  height="100"
                  width="100"
                />
                <div>
                  <div class="font-medium">
                    <div v-if="contact?.first_name && contact?.last_name">{{ contact.first_name }} {{ contact.last_name }}</div>
                    <div v-else-if="contact?.first_name">{{ contact.first_name }}</div>
                  </div>

                  <div class="-ml-2 text-sm">
                    <div class="-space-y-1">
                      <div v-if="contact?.email" class="flex items-center space-x-1">
                        <div class="flex h-8 w-8 items-center justify-center text-supplement">
                          <Icon icon="envelope" />
                        </div>
                        <div>
                          <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
                        </div>
                      </div>

                      <div v-if="contact?.phone" class="flex items-center space-x-1">
                        <div class="flex h-8 w-8 items-center justify-center text-supplement">
                          <Icon icon="phone" />
                        </div>
                        <div>{{ contact.phone }}</div>
                      </div>
                    </div>

                    <!-- <div class="mt-2 flex">
                    <a href="#" class="ag-inspires-header__social-link group flex h-8 w-8 items-center justify-center">
                      <Icon icon="twitter" prefix="fab" />
                    </a>
                    <a href="#" class="ag-inspires-header__social-link group flex h-8 w-8 items-center justify-center">
                      <Icon icon="facebook" prefix="fab" />
                    </a>
                    <a href="#" class="ag-inspires-header__social-link group flex h-8 w-8 items-center justify-center">
                      <Icon icon="instagram" prefix="fab" />
                    </a>
                    <a href="#" class="ag-inspires-header__social-link group flex h-8 w-8 items-center justify-center">
                      <Icon icon="linkedin" prefix="fab" />
                    </a>
                  </div> -->
                  </div>
                </div>
              </div>

              <div v-if="callToAction" class="mt-8">
                <Button as="a" :href="callToAction?.link" target="_blank" append-icon="long-arrow-right" class="ag-block__button">{{
                  callToAction?.label
                }}</Button>
              </div>

              <div v-if="partnerLogo" class="mt-8">
                <img
                  :src="partnerLogo.full_url"
                  :class="{
                    'max-h-10 max-w-xs': partnerLogo.full_url.includes('-ag-landscape'),
                    'max-h-12 max-w-xs': partnerLogo.full_url.includes('-ag-square'),
                    'max-h-9 max-w-xxs': !partnerLogo.full_url.includes('-ag-landscape') && !partnerLogo.full_url.includes('-ag-square'),
                  }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <div
      v-if="backgroundImage"
      class="relative ml-6 mt-6 hidden overflow-hidden rounded-l-lg lg:absolute lg:inset-y-0 lg:left-1/2 lg:-ml-24 lg:mt-0 lg:block lg:h-full lg:w-2/3 lg:rounded-tl-none"
    >
      <Image :media="backgroundImage" responsive class="absolute inset-0 h-full w-full object-cover" />
    </div>
  </header>
</template>

<script setup lang="ts">
const props = defineProps<{
  customisations: Customisations;
  backgroundImage?: Media;
  contact?: Contact;
  title?: string;
  logo?: Media;
  partnerLogo?: Media;
  message?: string;
  personalPhoto?: Media;
  personalMessage?: string;
  callToAction?: {
    link: string;
    label: string;
  };
}>();

const titleFontFamily =
  props.customisations.collection_title_font_type === "google-font"
    ? props.customisations.google_fonts.collection_title.family
    : props.customisations.collection_title_websafe_font_family;

const titleFontSize = TailwindConfig.fontSize[props.customisations.inspires_collection_title_font_size || "2xl"];
const titleFontWeight = props.customisations.collection_title_font_type === "google-font" ? props.customisations.google_fonts.collection_title.weight : 600;
</script>

<style lang="postcss">
.ag-inspires-header {
  &__title {
    font-family: v-bind(titleFontFamily);
    font-size: v-bind("titleFontSize.fontSize");
    font-weight: v-bind(titleFontWeight);
    line-height: v-bind("titleFontSize.lineHeight");
  }

  &__social-link {
    @apply text-supplement transition-colors hover:text-primary !important;
  }

  &__quote {
    @apply relative;

    &:after,
    &:before {
      @apply left-10;
      top: 100%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }

    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: theme("colors.gray.300");
      border-width: 11px;
      margin-left: -11px;
    }
  }
}
</style>
